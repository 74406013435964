import { createApp } from 'vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "vue-select/dist/vue-select.css";

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import { VOffline } from 'v-offline';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import * as Popper from '@popperjs/core';
import './assets/main.css'


window.onerror = function(msg, url, line, col, error) {
    // Note that col & error are new to the HTML 5 spec and may not be 
    // supported in every browser.  It worked for me in Chrome.
    var extra = !col ? '' : '\ncolumn: ' + col;
    extra += !error ? '' : '\nerror: ' + error;
 
    // You can view the information in an alert to see things working like this:
    // alert("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);
 
    // TODO: Report this error via ajax so you can keep track
    //       of what pages have JS issues
 
    var suppressErrorAlert = true;
    // If you return true, then error alerts (like in older versions of 
    // Internet Explorer) will be suppressed.
    return suppressErrorAlert;
 };

if (location.hostname === "localhost" || location.hostname === "127.0.0.1" || location.hostname === "")
{
    window.api = "https://api.sba-brandservicesyd.se"; //"http://127.0.0.1:8000"; //"https://sba-api.joelmandell.se";
} else {
    window.api = "https://api.sba-brandservicesyd.se";
}


// import './assets/volt.css'
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App)

const options = {
    // You can set your default options here
};
app.use(Toast, options);

app.use("v-offline",VOffline);
app.use(VueCookies, { expire: '7d'})
app.use(pinia)
app.use(router)

app.mount('#app')
