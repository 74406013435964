<script setup>
    import { ref } from "vue";
    import { inject } from "vue";
    import { useRouter } from "vue-router";
    const $cookies = inject('$cookies');

    const router = useRouter();
    
    const error = ref(false);

    const postData = async(url = '', data = {}) => {
        // Default options are marked with *
        const response = await fetch(url, {
                withCredentials: true,
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    const getData = async(url = '', data = {}) => {
      // Default options are marked with *
      const response = await fetch(url, {
            method: 'GET',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            redirect: 'follow', // manual, *follow, error
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }

    const user = ref({email:"", password:""});

    const deleteCache = async () => {
      const caches = await window.caches.open("files");
      const keys = await caches.keys();

      for (const key of keys) {
        caches.delete(key);
      }

      try {
        const cachesWorkbox = await window.caches.open("workbox-precache-v2-https://sba-brandservicesyd.se/");
        const keysWorkbox = await cachesWorkbox.keys();

        for (const key of keysWorkbox) {
          cachesWorkbox.delete(key);
        }
      } catch (ex) {
        console.log(ex)
      }
    }

    const login = async () => {
        const result = await postData(`${window.api}/api/auth/login`, user.value)
        if(result.hasOwnProperty("access_token")) {
          //reset page on signin.
          await deleteCache();
          
          $cookies.set("sba_token",result.access_token);
          $cookies.set("company_id",result.user[0].companyId)
          error.value = false;
          location.href="/select-service"
        } else {
          error.value = true;
        }
    }
</script>
<template>
  <main>
    <!-- Section -->
    <section class="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
      <div class="container">
        <!--
        <p class="text-center">
            <a href="#" class="d-flex align-items-center justify-content-center">
                <img src="./assets/img/logo-brandservice-syd.png" style="width:300px;height:auto;" />
            </a>
        </p>
        -->
        <div class="row justify-content-center">
          <div class="col-12 d-flex align-items-center justify-content-center">
            <div
              class="
                bg-white
                shadow
                border-0
                rounded
                border-light
                p-4 p-lg-5
                w-100
                fmxw-500
              "
            >
              <div class="text-center text-md-center mb-4 mt-md-0">
                <h1 class="mb-0 h3">WEB-SBA</h1>
              </div>

              <div v-if="error" class="alert alert-danger">Ogiltigt användarnamn eller lösenord.</div>

              <div class="mt-4">
                <!-- Form -->
                <div class="form-group mb-4">
                  <label for="email">Ditt användarnamn</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">
                      <svg
                        class="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                        ></path>
                        <path
                          d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      name="email"
                      class="form-control"
                      placeholder="exempel@foretag.se"
                      id="email"
                      autofocus=""
                      required=""
                      v-model="user.email"
                    />
                    <span class="invalid-feedback"></span>
                  </div>
                </div>
                <!-- End of Form -->
                <div class="form-group">
                  <!-- Form -->
                  <div class="form-group mb-4">
                    <label for="password">Ditt lösenord</label>
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon2">
                        <svg
                          class="icon icon-xs text-gray-600"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <input
                        type="password"
                        name="password"
                        placeholder="Lösenord"
                        class="form-control"
                        id="password"
                        required=""
                        v-model="user.password"
                      />
                      <span class="invalid-feedback"></span>
                    </div>
                  </div>
                  <!-- End of Form -->
                  <div
                    class="d-flex justify-content-between align-items-top mb-4"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="remember"
                      />
                      <label class="form-check-label mb-0" for="remember">
                        Kom ihåg mig
                      </label>
                    </div>
                    <div>
                      <a href="./forgot-password" class="small text-right"
                        >Glömt lösenordet?</a
                      >
                    </div>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-gray-800" value="Login" @click="login()">
                    Logga in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>