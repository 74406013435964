<script setup>
// import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.0/js/bootstrap.min.js";
import "bootstrap";
import { RouterLink, RouterView } from "vue-router";
import MainMenu from "./components/MainMenu.vue";
import Login from "./components/Login.vue";
import { computed, inject, ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import TopBar from "./components/TopBar.vue";
const route = useRoute();
const authed = inject("$cookies").get("sba_token") != null;

const skipPaths = computed(
  () =>
    !route.fullPath.includes("forgot-password") &&
    !route.fullPath.includes("select-service") &&
    !route.fullPath.includes("select-mode") &&
    !route.fullPath.includes("drawing-mode") &&
    !route.fullPath.includes("schedule-mode")
);

</script>

<template>
  <div>
    <div v-if="authed && skipPaths">
      <MainMenu />

      <main class="content">
        <TopBar />
        <RouterView :key="route.fullPath" />

        <footer class="bg-white p-4 mt-4 hideWhenPrint">
          <div class="row">
            <div class="col-12 mb-md-0">
              <p class="mb-0 text-center text-lg-start">
                © <span class="current-year">2022</span>
                <a
                  class="text-primary fw-normal"
                  href="https://#"
                  target="_blank"
                  >Brandservice Syd AB</a
                >
                | WEB-SBA version: 1.0.2
              </p>
            </div>
          </div>
        </footer>
      </main>
    </div>
    <div v-else-if="authed && !skipPaths">
      <RouterView :key="route.fullPath" />
    </div>
    <div v-else>
      <Login v-if="!authed && skipPaths" />
      <div v-else>
        <RouterView :key="route.fullPath" />
      </div>
    </div>
  </div>
</template>

<style>
.btn-secondary {
  background-color: #da291c !important; 
  color:#fff !important;
}
</style>

<style scoped>
header {
  line-height: 1.5;
  max-height: 100vh;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}
nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
