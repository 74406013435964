import { defineStore } from 'pinia'
import VueCookies from 'vue-cookies'
import isOnline from 'is-online';
import { useToast } from "vue-toastification";
import { ref } from 'vue';

const postData = async(url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${VueCookies.get("sba_token")}`,
            'company_id': `${VueCookies.get("company_id")}`
          },
          redirect: 'follow', // manual, *follow, error
          body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const getData = async(url = '', data = {}) => {
  // Default options are marked with *
  const response = await fetch(url, {
        method: 'GET',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${VueCookies.get("sba_token")}`,
          'company_id': `${VueCookies.get("company_id")}`
        },
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const cacheHelper = async (filename,expression, store='files', withOnlineCheck = false, replace = false) => {
  const companyId = VueCookies.get("company_id");
  const completePath = `/data/${companyId}/${filename}.json`;

  const caches = await window.caches.open(store);
  const cacheItem = await (await caches.match(completePath))

  const online = await isOnline();

  if(!cacheItem || (withOnlineCheck && online) || replace) {
    //If cache does not exist. Store it in cache.
    const dataToStore = await expression();

    const response = new Response(JSON.stringify(dataToStore));
    response.headers.append("Content-Type","application/json;charset=iso-8859-1");

    caches.put(completePath,response);
    return dataToStore;
  } else {
    const fileCache = await cacheItem.json();
    return fileCache;
  }
}

export const reviewStore = ref({deleted:[]});

export const useTemplateStore = defineStore({
  id:"template",
  state: () => ({data:[],schedule:[],products:[]}),
  actions: {   
      async createScheduleList() {
        const data = await getData(`${window.api}/api/auth/createScheduleList`)
        return data;
      },
      async deleteScheduleList(id) {
        const data = await getData(`${window.api}/api/auth/deleteScheduleList/${id}`)
        return data;
      },
      async deleteIncident(id) {
        return (await cacheHelper("incidents",() => postData(`${window.api}/api/auth/deleteIncident/${id}`),"files",true));
      },
      async updateIncident(data) {
        return (await cacheHelper("incidents",() => postData(`${window.api}/api/auth/updateIncident`,data),"files",true));
      },
      async createIncident(data) {
        return await postData(`${window.api}/api/auth/createIncident`,data);
      },
      async incidents() {
        return (await cacheHelper("incidents",() => getData(`${window.api}/api/auth/incidents`),"files",true));
      },
      async deleteReminder(id) {
        return (await cacheHelper("reminders",() => postData(`${window.api}/api/auth/deleteReminder/${id}`),"files",true));
      },
      async createReminder(data) {
        return (await cacheHelper("reminders",() => postData(`${window.api}/api/auth/createReminder`,data),"files",true));
      },
      async reminders() {
        return (await cacheHelper("reminders",() => getData(`${window.api}/api/auth/reminderSchedule`),"files",true));
      },
      async getPlacements() {
        return (await cacheHelper("placements",() => getData(`${window.api}/api/auth/placements`),"files",false))[0];
      },
      async savePlacements(placements) {
        return await cacheHelper("placements",() => postData(`${window.api}/api/auth/savePlacements`,placements),"files",true);
      },
      async getProducts() {
        return (await cacheHelper("products",() => getData(`${window.api}/api/auth/products`),"files",true))[0];
      },
      async saveProducts(product) {
        return await cacheHelper("products",() => postData(`${window.api}/api/auth/saveProducts`,product),"files",true);
      },
      async get() {
        return await cacheHelper("schedule-templates",() => getData(`${window.api}/api/auth/schedule-templates`),"files",true);
      }, 
      async getId(id) {
        return (await cacheHelper("schedule-templates",() => getData(`${window.api}/api/auth/schedule-templates`),"files",false)).find(x => x.id == id);
      }, 
      async fireServices() {
        return await cacheHelper("fireServices",() => getData(`${window.api}/api/auth/fireServices`),"files",true);
      },
      async addFireServices(data) {
        return await cacheHelper("fireServices",() => postData(`${window.api}/api/auth/addFireServices`,data),"files",true);
      },
      async okFireServices(data) {
        return await cacheHelper("fireServices",() => postData(`${window.api}/api/auth/updateFireServices`,data),"files",true);
      },
      async deleteFireService(data) {
        return await cacheHelper("fireServices",() => postData(`${window.api}/api/auth/deleteFireService`,data),"files",true);
      },
      async updateReview(id,data) { 
        const toast = useToast();

        toast.success("Redigerat egenkontroll", {
          timeout: 3000
        });
        
        await postData(`${window.api}/api/auth/updateReview/${id}`,data);
        return await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/doneReviews`),"files",true);
      },
      async getReview(id) {
        const result =  await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/doneReviews`),"files",false);
        return result.find(x => x.id == id);  
      },
      async undoDeletedReview(id) {
        //Delete and update cache.
        const data = await cacheHelper("deletedReviews",() => getData(`${window.api}/api/auth/undoDeletedReview/${id}`),"files",true);
        reviewStore.value.deleted = data; //await cacheHelper("deletedReviews",() => getData(`${window.api}/api/auth/deletedReviews`),"files",true);;

        return data;
      },
      async permDeleteReview(id) {
        //Delete and update cache.
        return await cacheHelper("deletedReviews",() => getData(`${window.api}/api/auth/permDeleteReview/${id}`),"files",true);
      },
      async deleteReview(id) {
        //Delete and update cache.
        const data = await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/deleteReview/${id}`),"files",true);
        reviewStore.value.deleted = data;//await cacheHelper("deletedReviews",() => getData(`${window.api}/api/auth/deletedReviews`),"files",true);

        return data;
      },
      async doneReviews(value) {
        const data = await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/doneReviews`),"files",true);
        return data;
      },
      async deletedReviews() {
        //Delete and update cache.
        const data = await cacheHelper("deletedReviews",() => getData(`${window.api}/api/auth/deletedReviews`),"files",true);
        reviewStore.value.deleted = data;

        return data;
      },
      async createReview(value) {
        const status = await isOnline();

        const toast = useToast();

        toast.success("Skapat schema för egenkontroll", {
          timeout: 3000
        });

        if(!status) {
          const cached =  await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/doneReviews`),"files",false);
          cached.push({id:Math.floor((Math.random() - 1) * 123456),items:value, company_id:value[0].company_id, date:value[0].date, start_time:value[0].start_time,controlled_by:value[0].controlled_by});
          return await cacheHelper("doneReviews",() => cached,"files",false, true);
        } else {
          const recieved = await postData(`${window.api}/api/auth/createReview`, value);
          // await cacheHelper("doneReviews",() => new Promise((resolve) => resolve(recieved[1])),"files",true,true);
          await cacheHelper("doneReviews",() => getData(`${window.api}/api/auth/doneReviews`),"files",true);
          return recieved;
        }
      },
      async update(value) {
        // if(status) data = await postData(`${window.api}/api/auth/update-schedule-template`, value);     
        const result = await cacheHelper("schedule-templates",() => postData(`${window.api}/api/auth/update-schedule-template`, value),"files",true);
        const toast = useToast();

        toast.success("Sparat schema för egenkontroll", {
          timeout: 3000
        });

        return result;
      },
      async getScheduleTemplates() {
        const status = await isOnline();
        let data = {};

        if(status) data = await getData(`${window.api}/api/auth/schedule-templates`);     

        if(status) this.$patch({schedule:data});

        if(!status) return this.schedule;

        return data;
      }
      
    },
    persist: true,
})


window.addEventListener('online', async (event) => {
  // const store = useTemplateStore();

  // console.log("%cHELLO ONLINE","font-size:5rem")
  // //För egen-kontroller
  // const updateValues = await cacheHelper("schedule-templates",() => getData(`${window.api}/api/auth/schedule-templates`),"files",false);
  // await cacheHelper("schedule-templates",() => postData(`${window.api}/api/auth/update-schedule-template`, updateValues),"files",true);

  // const toast = useToast();

  // toast.success("Vi är tillbaka online och vi har synkat dina förändringar", {
  //   timeout: 20000
  // });
});
