import { defineStore } from 'pinia'
import VueCookies from 'vue-cookies'
import isOnline from 'is-online';
import { useToast } from "vue-toastification";

const postData = async(url = '', data = {}) => {
  const response = await fetch(url, {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${VueCookies.get("sba_token")}`,
            'company_id': `${VueCookies.get("company_id")}`
          },
          redirect: 'follow', // manual, *follow, error
          body: JSON.stringify(data)
  });
  return response.json();
}

const cacheHelper = async (filename,expression, store='files', withOnlineCheck = false) => {
  const companyId = VueCookies.get("company_id");
  const completePath = `/data/${companyId}/${filename}.json`;

  const caches = await window.caches.open(store);
  const cacheItem = await (await caches.match(completePath))

  const online = await isOnline();

  if(!cacheItem || (withOnlineCheck && online)) {
    //If cache does not exist. Store it in cache.
    const dataToStore = await expression();
    const response = new Response(JSON.stringify(dataToStore));
    response.headers.append("Content-Type","application/json;charset=iso-8859-1");

    caches.put(completePath,response);
    return dataToStore;
  } else {
    const fileCache = await cacheItem.json();
    return fileCache;
  }
}

const postBlobData = async(url = '', data = {}) => {
  const response = await fetch(url, {
          method: 'POST',
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${VueCookies.get("sba_token")}`,
            'company_id': `${VueCookies.get("company_id")}`
          },
          body: data 
  });

  return response.text();
}

const getData = async(url = '', data = {}) => {
  const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${VueCookies.get("sba_token")}`,
          'company_id': `${VueCookies.get("company_id")}`
        },
        redirect: 'follow',
  });
  return response.json();
}

export const usePageStore = defineStore({
  id:"page",
  state: () => ({page:[],pages:[], files:[]}),
  actions: {   
    async createOrder(mails) {
      const data = await postData(`${window.api}/api/auth/createOrder`,mails);
      console.log(data)
      // return await cacheHelper("responsibilites",() => getData(`${window.api}/api/auth/responsibilities`),"files",false);
    },
    async getSchImage(id) {
    
      let img = await getData(`${window.api}/api/auth/schImage/${id}`); 

      //Update cache
      // await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",true);
      // await cacheHelper("fileInfo",() =>  getData(`${window.api}/api/auth/getFileInfo`, false),"files",true)

      return img;
    },
    async uploadSchImage(id,file) {
      let formdata = new FormData();
      formdata.append('image', file);
      formdata.append('filename', file.name);
      
      let img = await postBlobData(`${window.api}/api/auth/schImage/create/${id}`,formdata); 

      return JSON.parse(img);
    },
      async firesecurity() {
        return await cacheHelper("firesecurity",() => getData(`${window.api}/api/auth/firesecurity`),"files");
      },
      async firesecurityUpdate(item) {
        const result = await cacheHelper("firesecurity",() => postData(`${window.api}/api/auth/firesecurityUpdate`,item),"files",true);
        const toast = useToast();

        // or with options
        toast.success("Sparat Brandskyddsredogörelse", {
          timeout: 3000
        });
        return result;
      },
      async responsibilities() {
        return await cacheHelper("responsibilites",() => getData(`${window.api}/api/auth/responsibilities`),"files",false);
      },
      async deleteFileTab(id) {
        const res = await cacheHelper("fileTab",() => getData(`${window.api}/api/auth/deleteFileTab/${id}`),"files",true);

        return res;
      },
      async getFileTabs() {
        const res = await cacheHelper("fileTab",() => getData(`${window.api}/api/auth/fileTabs`),"files",true);

        return res;
      },
      async saveFileInfos(fileInfos) {
        await postData(`${window.api}/api/auth/saveFileInfos`,fileInfos);
        const toast = useToast();

        // or with options
        toast.success("Sparat filinformation", {
          timeout: 3000
        });

        //Update cache
        await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",true);
        await cacheHelper("fileInfo",() =>  getData(`${window.api}/api/auth/getFileInfo`, false),"files",true)

        return res;
      },
      async addFileTab(name) {
        const res = await cacheHelper("fileTab",() => postData(`${window.api}/api/auth/addFileTab/${name}`),"files",true);
        const toast = useToast();

        // or with options
        toast.success("Lagt till ny flik", {
          timeout: 3000
        });

        return res;
      },
      async saveResponsibilities(item) {
        const result = await postData(`${window.api}/api/auth/saveResponsibilities`,item);

        const toast = useToast();

        // or with options
        toast.success("Sparat ansvarsfördelning", {
          timeout: 3000
        });

        //Update cache
        await cacheHelper("responsibilites",() => result,"files",true);

        return result;
      },
      async futureEducations() {
        // return await getData(`${window.api}/api/auth/futureEducations`);
        return await cacheHelper("futureEducations",() => getData(`${window.api}/api/auth/futureEducations`),"files",false);
      },
      async saveFutureEducations(item) {
        const result = await postData(`${window.api}/api/auth/saveFutureEducations`,item);

        //reload cache
        await cacheHelper("futureEducations",() => result,"files",true);

        const toast = useToast();

        // or with options
        toast.success("Sparat utbildningar", {
          timeout: 3000
        });
        return result;
      },
      async educations() {
        return await cacheHelper("educations",() => getData(`${window.api}/api/auth/educations`),"files",true);
      },
      async saveEducations(item) {
        const toast = useToast();

        // or with options
        toast.success("Sparat utbildningsplan", {
          timeout: 3000
        });

        return await postData(`${window.api}/api/auth/saveEducations`,item);
      },
      async organizations() {
        return await getData(`${window.api}/api/auth/organizations`);
      },
      async saveOrganizations(item) {
        const toast = useToast();

        // or with options
        toast.success("Sparat", {
          timeout: 3000
        });

        return await postData(`${window.api}/api/auth/saveOrganizations`,item);
      },
      async userProfile() {
        try {
          let user = await cacheHelper("userProfile",() => getData(`${window.api}/api/auth/user-profil`),"files",false);
          user.user_role = Number(user.user_role);
          return user;
        } catch {
          return {}
        }
      },
      async removeCompany(id) {
        return await getData(`${window.api}/api/auth/removeCompany/${id}`);
      },
      async mailAdmin() {
        try {
          return await getData(`${window.api}/api/auth/admin/mail`);
        } catch {
          return {}
        }
      },
      async mailUpdateAdmin(settings) {
        try {
          return await postData(`${window.api}/api/auth/admin/update`,settings);
        } catch {
          return {}
        }
      },
      async newpw(user) {
        try {
          return await postData(`${window.api}/api/auth/newpw`,user);
        } catch {
          return false
        }
      },
      async resetpw(mail) {
        try {
          return await getData(`${window.api}/api/auth/reset?mail=${mail}&name=${mail}`);
        } catch {
          return {}
        }
      },
      async userCreate(user) {
        return await postData(`${window.api}/api/auth/createUser`,user);
      },
      async users() {
        const status = await isOnline();

        if(status) return await getData(`${window.api}/api/auth/users`);     
      },
      async deletePage(id) {
        return await cacheHelper("pages",() => getData(`${window.api}/api/auth/pageDelete/${id}`),"files",true);
      },
      async createPage(value) {
        const toast = useToast();
        
        toast.success("Skapat sidan " + value[0].title, {
          timeout: 3000
        });

        return await cacheHelper("pages",() => postData(`${window.api}/api/auth/pageCreate`, value),"files",true);
      },
      async updatePage(value) {
        const toast = useToast();
        
        toast.success("Uppdaterat sidan " + value[0].title, {
          timeout: 3000
        });

        return await cacheHelper("pages",() => postData(`${window.api}/api/auth/pageUpdate`, value),"files",true); 
      },
      async getFileInfo() {
        return await cacheHelper("fileInfo",() =>  getData(`${window.api}/api/auth/getFileInfo`, false),"files",true);
      },
      async saveEducationMetadata(product) {
        return await postData(`${window.api}/api/auth/saveEducationMetadata`,product);
      },
      async metadataProducts(product) {
        return await cacheHelper("metadataProduct",() => getData(`${window.api}/api/auth/metadataProduct/${product}`),"files",true);
        return data;
      },
      async metadata() {
        let data = await getData(`${window.api}/api/auth/metadata`);
        return data;
      },
      async metadataId(id) {
        let data = await getData(`${window.api}/api/auth/metadata/${id}`);
        return data;
      },
      async companies() {
        let data = await getData(`${window.api}/api/auth/companies`);
        return data;
      },
      async createCompany(company) {
        let data = await postData(`${window.api}/api/auth/createCompany`,{company_name:company,owner:1});
        console.log(data)
        return {};
      },
      async deleteImage(id) {
        let data = await getData(`${window.api}/api/auth/deleteImage/${id}`);

        //update cache with new files.
        await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",true);

        return data;
      },
      async uploadImage(file, category = "5", desc = "Karta", fileTabId = 0) {
        let formdata = new FormData();
        formdata.append('image', file);
        formdata.append('filename', file.name);
        formdata.append('category',category);
        formdata.append('desc',desc);
        formdata.append('fileTabId',fileTabId);

        let img = await postBlobData(`${window.api}/api/auth/file`,formdata); 

        //Update cache
        await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",true);
        await cacheHelper("fileInfo",() =>  getData(`${window.api}/api/auth/getFileInfo`, false),"files",true)

        const toast = useToast();

        // or with options
        toast.success("Fil är uppladdad", {
          timeout: 3000
        });

        return img;
      },
      async getFile(id) {
        const result = await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",false);

        return result[id];
      },
      async getFiles() {
        return await cacheHelper("files",() => getData(`${window.api}/api/auth/files`),"files",false);
      }, 
      async getPage(id, onlineCheck = false) {
        // return await getData(`${window.api}/api/auth/page/${id}`); 
        return cacheHelper(`page${id}`,() => getData(`${window.api}/api/auth/page/${id}`),"files",onlineCheck);
      }, 
      async getPages() {
        // return  await getData(`${window.api}/api/auth/pages`); 
        return cacheHelper("pages",() => getData(`${window.api}/api/auth/pages`),"files",false);
      }, 
    },
    persist: true,
})

window.addEventListener('online', async (event) => {
  const store = usePageStore();

  //Updatera med synkad data.
  // await postData("http://127.0.0.1:8000/api/auth/update-schedule-template", store.data);     
});