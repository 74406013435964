import { createRouter, createWebHistory, RouterView } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Start.vue')
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('../views/ForgotPassword.vue')
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('../views/ChangePassword.vue')
    },
    {
      path: '/select-service',
      name: 'select-service',
      component: () => import('../views/Start/SelectService.vue')
    },
    {
      path: '/control/select-mode',
      name: 'select-mode',
      component: () => import('../views/Start/SelectMode.vue')
    },
    {
      path: '/control/drawing-mode/:typeId?',
      name: 'drawing-mode',
      component: () => import('../views/Control/DrawingMode.vue'),
    },
    {
      path: '/control/recyclebin',
      name: 'deleted',
      component: () => import('../views/Control/RecycleBin.vue'),
    },
    {
      path: '/plannedService',
      name: 'plannedService',
      component: () => import('../views/Control/UpcomingService.vue'),
    },
    {
      path: '/doneService',
      name: 'doneService',
      component: () => import('../views/Control/DoneService.vue'),
    },
    {
      path: '/control/schedule-mode',
      name: 'schedule-mode',
      component: () => import('../views/Control/ScheduleMode.vue')
    },    
    {
      path: '/admin/mail',
      name: 'admin-mail',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/Mail.vue')
    },
    {
      path: '/admin/companies',
      name: 'admin-companies',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/Companies.vue')
    },
    {
      path: '/admin/pages',
      name: 'admin-pages',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/PageList.vue')
    },
    {
      path: '/admin/page/:id',
      name: 'admin-page',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/Page.vue')
    },
    {
      path: '/admin/metadata',
      name: 'metadata',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/Metadata.vue')
    },
    {
      path: '/admin/users',
      name: 'users',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/Users.vue')
    },
    {
      path: '/admin/user/:id',
      name: 'user',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Admin/User.vue')
    },
    {
      path: '/uploads',
      name: 'uploads',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Start/Uploads.vue')
    },
    {
      path: '/organization',
      name: 'organization',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Organization/Organization.vue')
    },
    {
      path: '/upcoming-educations',
      name: 'upcoming-educations',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Education/UpcomingEducations.vue')
    },
    {
      path: '/educationplan',
      name: 'educationplan',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Education/EducationPlan.vue')
    },
    {
      path: '/done-educations',
      name: 'done-educations',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Education/DoneEducations.vue')
    },
    {
      path: '/page/:id',
      name: 'page',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Laws/Presenter.vue'),
    },
    {
      path: '/statements/firesecurity',
      name: 'statement-firesecurity',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/WrittenStatements/FireSecurityStatement.vue')
    },
    {
      path: '/border/responsibility',
      name: 'border-responsibility',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/Border/Responsibility.vue')
    },
    {
      path: '/control/control-template',
      name: 'control-template',
      component: () => import('../views/Control/ControlTemplate.vue')
    },
    {
      path: '/control/create-review/:id',
      name: 'control-create-review',
      component: () => import('../views/Control/CreateReview.vue')
    },
    {
      path: '/control/edit-review/:id',
      name: 'control-edit-review',
      component: () => import('../views/Control/CreateReview.vue')
    },
    {
      path: '/control/done-reviews/',
      name: 'control-done-reviews',
      component: () => import('../views/Control/DoneReviews.vue')
    },
    {
      path: '/control/drawings/:id',
      name: 'control-drawings',
      component: () => import('../views/Control/EditDrawings.vue')
    },
    {
      path: '/control/reminders',
      name: 'control-reminders',
      component: () => import('../views/Control/Reminders.vue')
    },
    {
      path: '/accidentReports',
      name: 'accident-reports',
      component: () => import('../views/Control/AccidentReports.vue')
    }
  ]
})

router.afterEach((to,from) => {
  try {
    console.log(to,from)
    if(Array.from(document.querySelector("#sidebarMenu")?.classList)?.find(x => x == "show") ?? [])
    {
      if(from.fullPath != "/") {
        document.querySelector("#sidebarMenu > div > div > div > a")?.click();
      }
    }
  } catch {}
})

export default router
