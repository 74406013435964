<script setup>
import { onMounted, ref } from "vue";

import { usePageStore } from "../stores/pages";
import { useTemplateStore, reviewStore } from "../stores/template";

const templateStore = useTemplateStore();
const deletedReviews = ref([]);

const reviews = reviewStore;
console.log(reviews)

const tasks = ref();
const store = usePageStore();

const pages = ref([]);
const logo = new URL("/assets/logo-web.png", import.meta.url);
const user = ref();

onMounted(async () => {
  try {
    user.value = await store.userProfile();
    tasks.value = await templateStore.get();
    pages.value = await store.getPages();
    deletedReviews.value = await templateStore.deletedReviews();
  } catch {}
});
</script>
  
  <template>
  <div class="bg-gray-100">
    <nav class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-lg-none">
      <a class="navbar-brand me-lg-5" href="../../index.html">
        <!-- <img class="navbar-brand-dark" src="@/resources/img/logo-web.png" alt="Brandservice Syd" />  -->
        <!-- <img class="navbar-brand-light" src="../../assets/img/brand/dark.svg" alt="Brandservice Syd" /> -->
      </a>
      <div class="d-flex align-items-center">
        <button
          class="navbar-toggler d-lg-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>

    <nav
      id="sidebarMenu"
      class="sidebar d-lg-block bg-white collapse"
      data-simplebar
    >
      <div class="sidebar-inner px-4 pt-3">
        <div
          class="
            user-card
            d-flex d-md-none
            align-items-center
            justify-content-between justify-content-md-center
            pb-4
          "
        >
          <div class="collapse-close d-md-none">
            <a
              href="#sidebarMenu"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <svg
                class="icon icon-xs"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>

        <RouterLink to="/select-service">
          <img
            src="/logo-web.png"
            class="logo-sidebar"
            height="64"
            width="212"
            alt="Brandservice Syd AB"
          />
        </RouterLink>
        <ul class="nav flex-column pt-3 pt-md-0">
          <!-- Välkommen -->
          <li class="nav-item">
            <RouterLink to="/" class="nav-link">
              <span class="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="icon icon-xs me-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"
                  ></path>
                </svg>
              </span>
              <span class="sidebar-text">Välkommen</span>
            </RouterLink>
          </li>

          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-skriftlig"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Skriftlig redogörelse</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-skriftlig"
              aria-expanded="true"
            >
              <ul class="flex-column nav">
                <li class="nav-item">
                  <RouterLink class="nav-link" to="/statements/firesecurity">
                    <span class="sidebar-text">Brandskyddsredogörelse</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Gränsdragningslista -->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-gransdragning"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Gränsdragningslista</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-gransdragning"
              aria-expanded="false"
            >
              <ul class="flex-column nav">
                <li class="nav-item">
                  <RouterLink class="nav-link" to="/border/responsibility">
                    <span class="sidebar-text">Ansvarsfördelning</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Brandskyddsorganisation -->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-brandskyddsorganisation"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Brandskyddsorganisation</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-brandskyddsorganisation"
              aria-expanded="false"
            >
              <ul class="flex-column nav">
                <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter(
                    (x) => x.slug == 'brandskyddsorganisation-information'
                  )"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">
                    <span class="sidebar-text">{{ page.title }}</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink class="nav-link" :to="`/organization`">
                    <span class="sidebar-text">Organisation</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Brandskyddspolicy -->
          <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter((x) => x.category == 'brandskyddspolicy')"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">
                    <span class="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="icon icon-xs me-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                  ></path>
                  <path
                    d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                  ></path>
                </svg>
              </span>
              <span class="sidebar-text">Brandskyddspolicy</span></RouterLink>
                </li>
      

          <!-- Lagar och föreskrifter-->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-lagar"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Lagar och föreskrifter</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div class="multi-level collapse" role="list" id="submenu-lagar">
              <ul class="flex-column nav">
                <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter((x) => x.category == 'lagar')"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">{{
                    page.title
                  }}</RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Egenkontroll -->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-egenkontroll"
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                    ></path>
                    <path
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    ></path>
                    <path
                      d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Egenkontroll</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-egenkontroll"
              aria-expanded="false"
            >
              <ul class="flex-column nav">
                <!-- Egenkontroll -> Instruktioner -->
                <li class="nav-item">
                  <span
                    class="
                      nav-link
                      collapsed
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    data-bs-toggle="collapse"
                    data-bs-target="#submenu-instruktioner"
                  >
                    <span>
                      <span class="sidebar-text">Instruktioner</span>
                    </span>
                    <span class="link-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="icon icon-sm-chevron"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  <div
                    class="multi-level collapse"
                    role="list"
                    id="submenu-instruktioner"
                    aria-expanded="false"
                  >
                    <ul class="flex-column nav">
                      <li
                        class="nav-item"
                        :key="'page' + page.id"
                        v-for="page in pages.filter(
                          (x) => x.category == 'instruktioner'
                        )"
                      >
                        <RouterLink class="nav-link" :to="`/page/${page.id}`">
                          <span class="sidebar-text">{{ page.title }}</span>
                        </RouterLink>
                      </li>
                      <li
                        role="separator"
                        class="dropdown-divider mt-1 mb-1 border-gray-700"
                      ></li>
                    </ul>
                  </div>
                </li>

                <li class="nav-item">
                  <span
                    class="
                      nav-link
                      collapsed
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    data-bs-toggle="collapse"
                    data-bs-target="#submenu-tasks"
                  >
                    <span>
                      <span class="sidebar-text">Tillgängliga egenkontroller</span>
                    </span>
                    <span class="link-arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="icon icon-sm-chevron"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  <div
                    class="multi-level collapse"
                    role="list"
                    id="submenu-tasks"
                    aria-expanded="false"
                  >
                    <ul class="flex-column nav">
                      <li
                        class="nav-item"
                        :key="'task' + idx"
                        v-for="(task,idx) in tasks"
                      >
                        <RouterLink class="nav-link" :to="`/control/create-review/${task.id}`">
                          <span class="sidebar-text">{{ task.name }}</span>
                        </RouterLink>
                      </li>
                      <li
                        role="separator"
                        class="dropdown-divider mt-1 mb-1 border-gray-700"
                      ></li>
                    </ul>
                  </div>
                </li>

                <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter(
                    (x) => x.category == 'åtgärdsplan'
                  )"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">
                    <span class="sidebar-text">{{ page.title }}</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink to="/control/control-template" class="nav-link">
                    <span class="sidebar-text">Schema för egenkontroll</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink to="/control/done-reviews" class="nav-link">
                    <span class="sidebar-text">Genomförda egenkontroller</span>
                  </RouterLink>
                </li>
                <li
                  class="nav-item"
                >
                  <RouterLink class="nav-link" :to="`/control/reminders`">
                    <span class="sidebar-text">Påminnelser för egenkontroll</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Uppladdade dokument -->
          <li class="nav-item">
            <RouterLink to="/uploads" class="nav-link">
              <span class="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="icon icon-xs me-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                  ></path>
                  <path
                    d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"
                  ></path>
                </svg>
              </span>
              <span class="sidebar-text">Uppladdade dokument</span>
            </RouterLink>
          </li>

          <!--Brandskyddsrutiner-->
           <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-brandskyddsrutiner"
              aria-expanded="false"
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Brandskyddsrutiner</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-brandskyddsrutiner"
              aria-expanded="false"
            >
              <ul class="flex-column nav">
                <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter(
                    (x) => x.category == 'brandskyddsrutiner'
                  )"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">
                    <span class="sidebar-text">{{ page.title }}</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- Utbildning-->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-utbildning"
              aria-expanded="false"
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Utbildning</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-utbildning"
              aria-expanded="false"
            >
              <ul class="flex-column nav">
                <li
                  class="nav-item"
                  :key="'page' + page.id"
                  v-for="page in pages.filter(
                    (x) => x.slug == 'utbildning'
                  )"
                >
                  <RouterLink class="nav-link" :to="`/page/${page.id}`">
                    <span class="sidebar-text">{{ page.title }}</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink class="nav-link" to="/educationplan">
                    <span class="sidebar-text">Utbildningsplan</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink class="nav-link" to="/upcoming-educations">
                    <span class="sidebar-text">Kommande utbildningar</span>
                  </RouterLink>
                </li>
                <li class="nav-item">
                  <RouterLink class="nav-link" to="/done-educations">
                    <span class="sidebar-text">Genomförda utbildningar</span>
                  </RouterLink>
                </li>
              </ul>
            </div>
          </li>

          <!-- TILLBUD -->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-accidents"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Tillbudsrapportering</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-accidents"
              aria-expanded="false"
            >
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/accidentReports`"
                  >Tillbudsrapport</RouterLink
                >
              </li>              
            </div>
          </li>

          <!-- TBrandredsskapsservice -->
          <li class="nav-item">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-service"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">Brandredskapsservice</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-service"
              aria-expanded="false"
            >
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/plannedService`"
                  >Kommande brandredskapsservice</RouterLink
                >
              </li>        
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/doneService`"
                  >Genomförda brandredksapsservice</RouterLink
                >
              </li>         
            </div>
          </li>

          <!-- papperskorg -->
          <li class="nav-item">
            <RouterLink to="/control/recyclebin" class="nav-link d-flex align-items-center">
              <span class="sidebar-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="icon icon-xs me-2" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                </svg>
              </span>
              <span class="sidebar-text">Papperskorg <span class="badge badge-sm bg-gray-200 ms-1 text-gray-800">{{ reviews.deleted?.length }}</span></span>
            </RouterLink>
          </li>


          <!-- ADMIN -->
          <li class="nav-item" v-if="user?.user_role > 1">
            <span
              class="
                nav-link
                collapsed
                d-flex
                justify-content-between
                align-items-center
              "
              data-bs-toggle="collapse"
              data-bs-target="#submenu-admin"
              aria-expanded=""
            >
              <span>
                <span class="sidebar-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="icon icon-xs me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z"
                    ></path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
                    ></path>
                  </svg>
                </span>
                <span class="sidebar-text">ADMIN</span>
              </span>
              <span class="link-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="icon icon-sm-chevron"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </span>
            </span>
            <div
              class="multi-level collapse"
              role="list"
              id="submenu-admin"
              aria-expanded="false"
            >
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/admin/users`"
                  >Administratörer</RouterLink
                >
              </li>              
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/admin/companies`"
                  >Kunder</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/admin/mail`"
                  >Mail</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/admin/pages`"
                  >Innehåll</RouterLink
                >
              </li>
              <li class="nav-item">
                <RouterLink class="nav-link" :to="`/admin/metadata`"
                  >Metadata</RouterLink
                >
              </li>
            </div>
          </li>
          <li
            role="separator"
            class="dropdown-divider mt-4 mb-3 border-gray-200"
          ></li>
          <li class="nav-item" v-if="false">
            <a
              href="#"
              target="_blank"
              class="nav-link d-flex align-items-center"
            >
              <span class="sidebar-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="icon icon-xs me-2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span>
              <span class="sidebar-text"
                >Papperskorg
                <span class="badge badge-sm bg-gray-200 ms-1 text-gray-800"
                  >0</span
                ></span
              >
            </a>
          </li>
          <li
            v-if="false"
            class="nav-item mt-4"
            style="padding-left: 10px; padding-right: 10px"
          >
            <a
              href="#"
              class="
                btn btn-gray-100
                shadow
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <span>Hjälp</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>