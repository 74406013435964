<script setup>
import { ref, onMounted, inject, computed } from "vue";
import { useRoute } from "vue-router";
import { usePageStore } from "../stores/pages";
import { cloneDeep, uniqBy } from "lodash";
import jwt_decode from "jwt-decode";

const store = usePageStore();

const companies = ref([]);
const $cookies = inject('$cookies');

const setCompany = (id) => {
  $cookies.set("company_id",id);
  location.reload();
}

const logout = () => {
  $cookies.remove("sba_token");
  location.reload();
}

const user = ref({})

onMounted(async () => {
  user.value = await store.userProfile();
  console.log(user.value)
  companies.value = await store.companies();
});
</script>
<template>
  <nav
    class="
      navbar navbar-top navbar-expand navbar-dashboard navbar-dark
      ps-0
      pe-2
      pb-0
    "
    v-if="user"
  >
    <div class="container-fluid px-0">
      <div
        class="d-flex justify-content-between w-100 hideWhenPrint"
        id="navbarSupportedContent"
      >
        <div class="d-flex align-items-center">
          <!-- Client navbar -->
          <div class="btn-group" style="margin-right: 5px" v-if="companies">
            <button type="button" class="btn btn-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="icon icon-xs me-2"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"
                ></path>
              </svg>
              {{companies.filter(x => x.id == $cookies.get("company_id"))[0]?.company_name}}
            </button>
            <button
              type="button"
              class="btn btn-white dropdown-toggle dropdown-toggle-split"
              id="dropdownMenuReference"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent"
            >
              <svg
                class="icon icon-xs"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul
              class="dropdown-menu py-0"
              aria-labelledby="dropdownMenuReference"
              style=""
            >
              <li :key="company.company_name" v-for="company in companies">
                <span
                    @click="setCompany(company.id)"
                    class="dropdown-item"
                    >{{company.company_name}}</span>
              </li>
            
            </ul>
          </div>
          <!-- Client navbar -->

          <!-- User navbar -->
          <div class="btn-group">
            <button type="button" class="btn btn-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="icon icon-xs me-2"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                ></path>
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
              </svg>
              {{user.username}} {{ user?.user_role == 2 ? " - ADMIN" : "" }}
            </button>
            <button
              type="button"
              class="btn btn-white dropdown-toggle dropdown-toggle-split"
              id="dropdownMenuReference"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent"
            >
              <svg
                class="icon icon-xs"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul
              class="dropdown-menu py-0"
              aria-labelledby="dropdownMenuReference"
              style=""
            >
              <li>
                <RouterLink to="/change-password" class="dropdown-item rounded-top">Ändra lösenord</RouterLink>
              </li>
              <li>
                <span @click="logout()" class="dropdown-item rounded-bottom"
                  >Logga ut</span
                >
              </li>
            </ul>
          </div>
          <!-- / User navbar -->
        </div>
      </div>
    </div>
  </nav>
</template>